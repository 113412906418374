<template>
  <div>
    <div class="businessDetail_title">
      <van-icon name="arrow-left" @click="toDetails"/>
      <div> {{ info.title }}</div>
    </div>
    <div class="businessDetail_content">
      <div class="businessDetail_content_detail">
        <div
          class="businessDetail_content_detail_img"
          v-html="info.formatContent"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "businessDetail",
  data() {
    return {
      info: {},
      businessList: [],
    };
  },
  mounted() {
    window.scroll(0, 0);
    const id = this.$route.query.id;
    this.getDetail(id);
  },
  methods: {
    getDetail(id) {
      //获取独立的数据
      axios
        .get(`https://admin.zoneyu.cn/api/admin/posts/${id}`, {
          headers: {
            "Admin-Authorization": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.info = res.data.data;
          const updateTime = new Date(this.info.createTime);
          this.info.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          this.info.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
          if (this.info.formatContent.indexOf("https") > -1) {
          } else {
            this.info.formatContent = this.info.formatContent.replaceAll(
              "/upload/",
              "https://admin.zoneyu.cn/upload/"
            );
          }
        });
    },
    toDetails() {
      this.$router.go(-1)
    },
  },
};
</script>
<style scoped lang="scss">
.businessDetail_title {
  width: calc(100% - 24px);
  padding: 0 12px;
  font-size: 12px;
  font-weight: 400;
  color: #565759;
  line-height: 33px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.businessDetail_content {
  width: calc(100% - 24px);
  padding: 0 12px;
  display: flex;
  .businessDetail_content_detail {
    width: 100%;
    .businessDetail_content_detail_head {
      display: flex;
    }
    /deep/ .businessDetail_content_detail_img {
      img {
        width: 100%;
      }
    }
  }
}
</style>
